import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAccount } from '../hooks'

export const Plans = () => {
  const { account } = useAccount()
  const { loginWithRedirect } = useAuth0()

  return (
    <div className="flex flex-col space-y-8 items-center lg:items-stretch lg:space-y-0 lg:flex-row w-full mt-16">
      <div className="flex-auto lg:relative lg:w-1/3 w-full max-w-sm">
        <div className="bg-white py-4 pr-4 pl-4 lg:pr-8 rounded-lg shadow-xl lg:absolute lg:-right-4 lg:left-0 lg:top-4 lg:bottom-4 flex flex-col items-center">
          <div className="p-4 flex flex-col items-center space-y-2 lg:h-32">
            <div className="text-2xl font-bold">Free</div>
            <div className="text-4xl font-bold flex space-x-4 items-end">
              $0.00
            </div>
          </div>
          <ul className="flex flex-col items-center space-y-1">
            <li>One 3D book cover</li>
            <li>Customize all 3D settings</li>
            <li>Embed the cover on your website</li>
          </ul>
          <div className="pt-8 pb-4">
            {!account ? (
              <button
                className="btn btn-secondary"
                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              >
                Sign up for free
              </button>
            ) : !account.subscription ? (
              <strong>Current plan</strong>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="relative flex-auto w-full max-w-sm lg:w-1/2 bg-white p-4 rounded-lg shadow-xl z-10 flex flex-col items-center border-2 border-blue-800">
        <div className="absolute -top-4 h-8 bg-blue-800 text-white flex items-center px-4 rounded-full text-xm">
          Launch deal
        </div>
        <div className="p-4 flex flex-col items-center space-y-2 lg:h-32">
          <div className="text-2xl font-bold">Premium</div>
          <div className="text-4xl font-bold flex space-x-4 items-end">
            <span className="line-through text-lg text-gray-500">$14.99</span>
            <span className="flex space-x-1 items-end">
              <span>$9.99</span>
              <span className="text-lg text-gray-500">/month</span>
            </span>
          </div>
        </div>
        <ul className="flex flex-col items-center space-y-1">
          <li>Unlimited 3D book covers</li>
          <li>Customize all 3D settings</li>
          <li>Embed the covers on your website</li>
        </ul>
        <div className="pt-8 pb-4">
          {!account ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                loginWithRedirect({
                  screen_hint: 'signup',
                  appState: { redirectTo: '/upgrade' },
                })
              }}
            >
              Sign up with premium features
            </button>
          ) : !account.subscription ? (
            <Link to="/upgrade" className="btn btn-primary">
              Upgrade to premium
            </Link>
          ) : (
            <strong>Current plan</strong>
          )}
        </div>
      </div>
      <div className="flex-auto lg:relative lg:w-1/3 w-full max-w-sm">
        <div className="bg-white py-4 lg:pl-8 pl-8 pr-4 rounded-lg shadow-xl lg:absolute lg:-left-4 lg:right-0 lg:top-4 lg:bottom-4 flex flex-col items-center">
          <div className="p-4 flex flex-col items-center space-y-2 lg:h-32">
            <div className="text-2xl font-bold">Professional</div>
            <div className="text-xl font-bold flex space-x-4 items-end">
              Contact us
            </div>
          </div>
          <p className="text-center leading-7">
            Want to automatically generate covers for lots of books? Get in
            touch for a custom solution.
          </p>
          <div className="pt-8 pb-4">
            <a href="mailto:threedbook@proton.me" className="btn btn-secondary">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
